<template>
  <div class="flex flex-wrap items-stretch mt-9" key="content">
          <PublicationCard 
            title="Неоадъювантная и адъювантная терапия колоректального рака II стадии" 
            expert="Фамилия Имя" 
            link="publications/1"
          />
  </div>
</template>

<script>
import PublicationCard from '@/components/publications/PublicationCard.vue'

export default {
  name: 'Publications',
  components: {
    PublicationCard
  }
}
</script>

<style>
</style>
