<template>
  <router-link
    :to="link" 
    class="flex cursor-pointer pr-2 md:pr-4 lg:pr-8 pb-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3">
    <div class="bg-white w-full h-full rounded-lg shadow-md p-4 flex flex-wrap">
        <p class="self-start text-left">Презентация к теме: </p>
        <h2 class="self-start text-2xl pt-2 font-medium text-primary-500 leading-7 max-w-lg mx-auto text-center">{{ title }}</h2>
        <p class="selft-start text-md pt-2 mx-auto text-secondary font-semibold">Эксперт: {{ expert }}</p>
        <SolidButton title="Изучить" :link="link" class="w-full self-end"/>
    </div>
  </router-link>
</template>

<script>
import SolidButton from '@/components/_partials/SolidButton.vue'

export default {
  name: 'PublicationCard',
  components: {
    SolidButton
  },
  props: {
    title: String,
    description: String,
    expert: String,
    link: String,
    img:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
